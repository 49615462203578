import {
  faBell,
  faBuilding,
  faCalendar,
  faGear,
  faList,
  faQuestionCircle,
  faRightToBracket,
  faUser,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { getConfig } from '../../../config'
import FaIconButton from '../../../elements/FaIconButton/FaIconButton'
import {
  m,
  stopPropagation,
  useStrictContext,
} from '../../../utils/react-utils'

import { PermissionV1, PermissionV2, R } from '@breezy/shared'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from 'antd'
import cn from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'
import ThinDivider from '../../../elements/ThinDivider'
import useAppNavigation from '../../../hooks/useAppNav'
import { useAuth } from '../../../hooks/useAuth'
import { useCompanyGuid } from '../../../providers/PrincipalUser'
import '../../../themes/tech-exp-intercom-override.css'
import { blue6 } from '../../../themes/theme'
import { AdminImpersonateUser } from '../../Admin/AdminImpersonateUser/AdminImpersonateUser'
import { useNewNotificationCount } from '../../Notifications/Notifications'
import { Authorized } from '../../Permissions/Authorized/Authorized'
import { PWAInstallPromptContext } from '../PWAInstallPrompt/PWAInstallPrompt'
import { TechAppUserSettingsPortal } from './TechAppUserSettingsPortal'
import './TechnicianAppBottomNavBar.less'

const { platformVersion, officeExperienceUrl } = getConfig()

const AppVersionLabel = m(() => (
  <div className="fixed bottom-0 right-1 z-50 text-[7px] text-gray-200">
    Version {platformVersion.slice(0, 7)}
  </div>
))

type MoreMenuProps = {
  open: boolean
  toggleMenu: () => void
  closeMenu: () => void
}

type MoreMenuItem = [
  string,
  IconDefinition,
  () => void,
  PermissionV1 | PermissionV2 | undefined,
]

const MoreMenu = React.memo<MoreMenuProps>(
  ({ open, toggleMenu, closeMenu }) => {
    const { logout } = useAuth()
    const companyGuid = useCompanyGuid()
    const [showUserSettings, setShowUserSettings] = useState(false)

    const { showPrompt: showPWAInstallPrompt } = useStrictContext(
      PWAInstallPromptContext,
    )

    const openIntercomHelp = useCallback(() => {
      if (typeof window.Intercom === 'function') {
        window.Intercom('show')
      }
    }, [])

    const isPWA = window.matchMedia('(display-mode: standalone)').matches

    const menuItems = useMemo<MoreMenuItem[]>(() => {
      const menuItems: MoreMenuItem[] = [
        ['Logout', faRightToBracket, logout, undefined],
        [
          'Office Experience',
          faBuilding,
          () => {
            window.location.href = officeExperienceUrl
          },
          PermissionV2.USE_OFFICE_EXPERIENCE,
        ],
        ['Help', faQuestionCircle, openIntercomHelp, undefined],
      ]
      if (companyGuid) {
        menuItems.push([
          'User Settings',
          faGear,
          () => setShowUserSettings(true),
          undefined,
        ])
      }
      if (!isPWA) {
        menuItems.push([
          'Install App',
          faDownload,
          showPWAInstallPrompt,
          undefined,
        ])
      }
      return menuItems
    }, [
      isPWA,
      logout,
      openIntercomHelp,
      showPWAInstallPrompt,
      setShowUserSettings,
      companyGuid,
    ])

    return (
      <>
        {
          <TechAppUserSettingsPortal
            isOpen={showUserSettings}
            onClose={() => {
              setShowUserSettings(false)
              closeMenu()
            }}
          />
        }
        {createPortal(
          <div
            className={cn('fixed inset-0 z-[2000]', {
              flex: open,
              hidden: !open,
            })}
            onClick={toggleMenu}
          >
            {/* We nest this in this way so the bottom nav is covered and counts as an outside
          click to dismiss. */}
            <div className="technician-navigation-drawer absolute inset-x-0 top-0 flex flex-col justify-end">
              <div className="bg-white p-4" onClick={stopPropagation}>
                {menuItems.map(([label, icon, onClick, permission], index) => (
                  <Authorized to={permission} allowsNonCompanyUser key={index}>
                    <React.Fragment key={index}>
                      <div
                        className="row center-children-v cursor-pointer text-lg"
                        onClick={() => {
                          toggleMenu()
                          onClick()
                        }}
                      >
                        <div className="flex w-8 items-center justify-start">
                          <FontAwesomeIcon icon={icon} className="text-lg" />
                        </div>
                        {label}
                      </div>
                      <ThinDivider />
                    </React.Fragment>
                  </Authorized>
                ))}

                <AdminImpersonateUser onImpersonateChange={toggleMenu} />
              </div>
            </div>
          </div>,
          document.body,
        )}
      </>
    )
  },
)

type NavButtonProps = {
  icon: IconDefinition
  onClick: () => void
  label: string
  routeMatch?: string
  count?: number
  style?: CSSProperties
}

const NavButton = m<NavButtonProps>(
  ({ icon, onClick, label, routeMatch, count, style }) => (
    <div
      className={cn('col center-children-vh', {
        blue6: routeMatch === window.location.pathname,
      })}
      style={style}
      onClick={onClick}
    >
      <Badge count={count} offset={[4, 14]}>
        <FaIconButton
          icon={icon}
          size={22}
          style={
            routeMatch === window.location.pathname ? { color: blue6 } : {}
          }
        />
      </Badge>

      <span className="text-[10px]">{label}</span>
    </div>
  ),
)

const TechnicianAppBottomNavBar = m(() => {
  const appNav = useAppNavigation()
  const [newNotificationsCount, setLatestNotificationReadAtDate] =
    useNewNotificationCount()

  const [moreMenuOpen, setMoreMenuOpen] = useState(false)

  const toggleMenu = useCallback(() => setMoreMenuOpen(R.not), [])
  const closeMenu = useCallback(() => setMoreMenuOpen(false), [])

  const onClickNotifications = useCallback(() => {
    appNav.navigateToNotifications()
    setLatestNotificationReadAtDate(undefined)
  }, [appNav, setLatestNotificationReadAtDate])

  return (
    <>
      {/* In mobile browsers, the entire view height isn't visible. You "scroll down"
          and the top chrome gets hidden. That means that when you load the app, the
          bottom nav is scrolled off the screen. It doesn't have the top chrome for
          PWA-mode so this isn't a problem. By doing this, we're creating a footer-sized
          space at the bottom, then making the actual footer fixed to the bottom of the
          page. In mobile browser mode, it will always be visible and as you "scroll" it
          just covers the empty space we're making. In PWA mode it's just fixed above
          that space. TODO: is there a way we can do "h-screen" in layout but actually
          make it "work" for mobile browsers? */}
      <div className="technician-experience-footer" />
      <div
        data-testid="tech-app-bottom-nav"
        className="technician-experience-footer technician-experience-footer-shadow row no-flex-wrap fixed inset-x-0 bottom-0 z-[999] flex w-full items-center justify-evenly bg-white pb-4"
      >
        <Authorized to={PermissionV2.USE_FIELD_EXPERIENCE}>
          <NavButton
            icon={faList}
            onClick={() => appNav.navigateToHome()}
            label="Appointments"
            routeMatch="/my-appointments"
            style={{ width: '61px' }}
          />
        </Authorized>
        <Authorized
          to={PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT}
        >
          <NavButton
            icon={faCalendar}
            onClick={() => appNav.navigateToSchedule()}
            label="Schedule"
            routeMatch="/schedule"
            style={{ width: '61px' }}
          />
        </Authorized>
        <Authorized to={PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL}>
          <NavButton
            icon={faUser}
            onClick={() => appNav.navigateToAccountList()}
            label="Accounts"
            routeMatch="/accounts"
            style={{ width: '61px' }}
          />
        </Authorized>
        <Authorized to={PermissionV2.USE_FIELD_EXPERIENCE}>
          <NavButton
            icon={faBell}
            onClick={onClickNotifications}
            label="Notifications"
            routeMatch="/notifications"
            count={newNotificationsCount}
            style={{ width: '61px' }}
          />
        </Authorized>
        <NavButton
          icon={faEllipsis}
          onClick={toggleMenu}
          label="More"
          style={{ width: '61px' }}
        />
        <MoreMenu
          open={moreMenuOpen}
          toggleMenu={toggleMenu}
          closeMenu={closeMenu}
        />

        <AppVersionLabel />
      </div>
    </>
  )
})

export default TechnicianAppBottomNavBar
